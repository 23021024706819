<template>
<div>
    <div class="wrap">
        <form name="mfrm" @submit.prevent="getPurchaseList" method="post">
            <div class="con_top">
                <h2><span>■</span>B2B 수납관리</h2>
                <div class="btns mb-30">
                    <a class="btn_new2 pointer" @click="register()">B2B 수납등록</a>
                </div>
            </div>
            <div class="con">
                <div class="con_table">
                    <div class="search2">
                        <div class="float-left dp-block mb-10">
                            <input type="text" v-model="value" class="w-200px h-40px pd-00 pl-20" placeholder="기업명">
                            <a @click="getPurchaseList()" class="btn_search ml-10 pointer">Search<span></span></a>
                        </div>
                    </div>
                    <table class="default_table mt-20">
                        <tbody v-if="loading">
                            <tr>
                                <th>No</th>
                                <th>기업명</th>
                                <th>총수납액</th>
                                <th>총 미수금액</th>
                                <th>관리</th>
                            </tr>
                            <tr>
                                <td colspan="4">검색중입니다.</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th>No</th>
                                <th>기업명</th>
                                <th>총수납액</th>
                                <th>총 미수금액</th>
                                <th>관리</th>
                            </tr>
                            <tr v-for="(item, index) of purchaseList" :key="index">
                                <td>{{ item.rownum }}</td>
                                <td>{{item.companyName}}</td>
                                <td>{{numberWithCommas(item.payPrice - item.refundPrice)}}원</td>
                                <td>{{numberWithCommas(item.noPrice)}}원</td>
                                <td><span class="underline pointer" @click="client(item.idxCrmCompany)">고객정보</span><span> | </span><span class="underline pointer" @click="detail(item.idxCrmCompany)">수납내역</span></td>
                            </tr>
                            <tr v-if="purchaseList.length === 0">
                                <td colspan="4">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
export default {
    data: () => ({
        value: '',
        type: 'B2B', // b2b 인지 B2B인지
        purchaseList: [],

        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
        loading:true,
    }),

    mounted() {

        if(sessionStorage.getItem('origin_acceptanceb2b_pageNum') !== 'undefined' && sessionStorage.getItem('origin_acceptanceb2b_pageNum') !== null) {
            this.pageNum = Number(sessionStorage.getItem('origin_acceptanceb2b_pageNum'))
        }
        if(sessionStorage.getItem('origin_acceptanceb2b_value') !== 'undefined' && sessionStorage.getItem('origin_acceptanceb2b_value') !== null) {
            this.value = sessionStorage.getItem('origin_acceptanceb2b_value')
        }

        this.getPurchaseList()
    },

    methods: {

        // 컴마 넣기
        numberWithCommas(x) {
            if(x === undefined) {
                return 0
            } else {
                x = x + ''
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        // 고개정보 
        client(idxCrmCompany) {
            this.$router.push(`/origin/customer_acceptance_client_b2b?idxCrmCompany=${idxCrmCompany}`)  
        },
        // 상세조회 페이지
        detail(idxCrmCompany) {
            this.$router.push(`/origin/customer_acceptance_view_b2b?idxCrmCompany=${idxCrmCompany}`)  
        },
        // 수납등록 페이지로 이동
        register () {
            let idxCrmCompany = -1
            this.$router.push(`/origin/customer_acceptance_register_b2b?idxCrmCompany=${idxCrmCompany}`)
        },
        getPurchaseList() {

            var params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                value: this.value,
                type: this.type
            }

            sessionStorage.setItem('origin_acceptanceb2b_pageNum', this.pageNum)
            sessionStorage.setItem('origin_acceptanceb2b_value', this.value)
            this.loading = true;
            this.axios.get('/api/v1/client/pur/list', {

                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.purchaseList = res.data.purList

                        if (res.data.purListCount) {
                            this.listLength = res.data.purListCount
                        }
                        this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                        this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                        this.pagePart = Math.ceil(this.pageNum / 10)
                    } else {
                        //alert('검색 결과가 없습니다.')
                        this.purchaseList = []
                    }
                })
                .catch(err => {
                    console.log(err)
                    alert('세션이 만료되어 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                })
                .finally(()=>{
                    this.loading = false;
                });
        },

        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getPurchaseList()
            }
        },
        paging(index) {
            this.pageNum = index
            this.getPurchaseList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getPurchaseList()
            }
        },
    }
}
</script>
